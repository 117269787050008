/** Ionic CSS Variables **/
body {
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;

  /** primary **/
  --ion-color-primary: #000000;
  --ion-color-primary-rgb: 227, 35, 40;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #000000;
  --ion-color-primary-tint: #1a1a1a;

  /** secondary **/
  --ion-color-secondary: #000000;
  --ion-color-secondary-rgb: 0, 0, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #000000;
  --ion-color-secondary-tint: #1a1a1a;

  /** tertiary **/
  --ion-color-tertiary: #08294a;
  --ion-color-tertiary-rgb: 8, 41, 74;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #072441;
  --ion-color-tertiary-tint: #213e5c;

  /** success **/
  --ion-color-success: #82b371;
  --ion-color-success-rgb: 130, 179, 113;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #729e63;
  --ion-color-success-tint: #8fbb7f;

  /** warning **/
  --ion-color-warning: #e8bb7d;
  --ion-color-warning-rgb: 232, 187, 125;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #cca56e;
  --ion-color-warning-tint: #eac28a;

  /** danger **/
  --ion-color-danger: #e32328;
  --ion-color-danger-rgb: 227, 35, 40;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c81f23;
  --ion-color-danger-tint: #e6393e;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #b3b3b3;
  --ion-color-medium-rgb: 179, 179, 179;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #9e9e9e;
  --ion-color-medium-tint: #bbbbbb;

  /** light **/
  --ion-color-light: #ececec;
  --ion-color-light-rgb: 236, 236, 236;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d0d0d0;
  --ion-color-light-tint: #eeeeee;
}
